<template>
    <div id="TreePanelSelect">
        <div style="width: 164px;" @click.stop="listVisible = !listVisible">
            <CSSelect>
                <div
                        v-if="deviceIds.length"
                        style="width: 130px; margin-left: 6px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
                >
                   已选择{{ deviceIds.length }}个
                </div>
                <div
                        v-else
                        style="width: 130px; margin-left: 6px; font-size: 15px; color: #999;"
                >
                    {{ tipsText }}
                </div>
            </CSSelect>
        </div>
        <div class="tree-source-panel" @click.stop v-show="listVisible" :style="{ '--width': width,'height':'500px' }">
            <div
                    style="
            height: 270px;
            width: 1035px;
            overflow-y: auto;
            margin-top: 20px;
          "
            >
                <table
                        border="1"
                        class="
              footable
              table table-stripped
              toggle-arrow-tiny
              modal-inner-table
            "
                        style="width: 1020px; margin: 0 0 20px 1px; font-size: 20px"
                >
                    <thead>
                    <tr class="head_row">
                        <th class="whilt" style="width: 95px">
                            <input
                                    style="vertical-align: middle"
                                    type="checkbox"
                                    @change="selectAll()"
                            />
                            <span style="vertical-align: middle; margin-left: 10px"
                            >全选</span
                            >
                        </th>
                        <th style="width: 130px">面板编号</th>
                        <th style="width: 140px">面板ID</th>
                        <th style="width: 135px">关联楼栋楼层</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                            v-for="(panel, index) in panelGroupPanelList"
                            :key="panel.id"
                            class="head_row"
                    >
                        <td class="center">
                            <input
                                    :checked="panel.checked"
                                    type="checkbox"
                                    @change="panelChecked(index)"
                            />
                        </td>
                        <td class="center">
                            {{ panel.deviceNo }}
                        </td>
                        <td class="center">
                            {{ panel.id }}
                        </td>
                        <td class="center">
                            <template v-if="panel.buildingName">
                                {{ `${panel.buildingName}/${panel.floor}` }}层
                            </template>
                           <template v-else>
                               -
                           </template>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import CSSelect from "@/components/common/CSSelect"

    export default {
        name: "ThreePanelSeletFrom",
        components: {
            CSSelect,
        },
        watch: {
            listVisible(visible) {
                if (visible) {
                    this.$emit('refresh')
                }
            },
            defaultSelect(val) {
                if (val !== '') {
                    this.selectedName = val;
                }
            }
        },
        props: {
            // 下拉框的宽度
            width: {
                default: "1055px",
                type: String,
            },
            // 隐藏请选择
            hideDefaultOption: {
                type: Boolean,
                default: false,
            },
            // 距离组件底部多远触发加载
            distance: {
                default: 20,
                type: Number
            },
            defaultSelect: String,
            // 显示的文字
            textName: {
                default: "title",
                type: String,
            },
            // 提示文案
            tipsText: {
                default: "请选择",
                type: String,
            },
            // 遍历的数据
            traverseData: Array,
            // 当前每一项的id
            itemId: {
                default: "id",
                type: String
            },
            // 判断是否是小程序调用
            isApplets: {
                default: false,
                type: Boolean
            },
            panelGroupPanelList: {
                default: false,
            },
            checked:{
                default:false
            },
            deviceIds:{
                default:[]
            }
        },
        mounted() {
            document.body.addEventListener('click', () => {
                this.listVisible = false;
            })
            if (this.defaultSelect) {
                this.selectedName = this.defaultSelect;
            }
            this.$vc.on('threePanelSelectFrom',() => {
                this.listVisible = false;
            })
        },
        data() {
            return {
                timer: null,
                // 下拉框显示的内容
                selectedName: "",
                // 列表的显隐
                listVisible: false,
                // 页数,必要时也可写成其他参数,主要应对小程序接口,如：87行
                pageNo: 1,
            }
        },
        methods: {
            // checkbox点击事件
            panelChecked(index) {

                this.$emit('panelChecked',index)

            },
            // 全选事件
            selectAll() {
                this.$emit('selectAll',{})
            },
            //查询授权面板列表
            selectPanelGroupPanelList() {

            },
            onClick(id, itemName) {
                this.selectedName = itemName;
                this.$emit('onClick', id);
                this.listVisible = false;
            }
        }
    };
</script>


<style lang="stylus" scoped>
    #TreePanelSelect
        position relative

    .tree-source-panel
        width var(--width, 250px)
        max-height 300px
        overflow-y auto
        background #FFFFFF
        z-index 1
        padding 5px 10px 10px 10px
        box-shadow 0 0 10px 0 rgba(0, 0, 0, 0.15)
        border-radius 10px
        position absolute
        left 0
        padding 0 10px
        border-top 10px solid #fff
        border-bottom 10px solid #fff

        div
            font-size 16px
            line-height 20px
            cursor pointer
            padding 10px 0
            border-bottom 1px solid #f0f0f0
</style>
